import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import tusas1 from "../../assets/img/tusas1.png"
import tusas2 from "../../assets/img/tusas-logo-yeni.png"
import tusas3 from "../../assets/img/tusas3.png"
import tusas4 from "../../assets/img/tusas-mmu-yeni.png"
import tusas5 from "../../assets/img/tusas2.png"
import tusas6 from "../../assets/img/tusas4.png"
import tusas7 from "../../assets/img/tusas-7.png"
import tusas8 from "../../assets/img/tusas-8.png"

export default function TusasProjesi() {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="TUSAŞ Projesi" />
      <div className="projeler-container page">
        <div className="ic-proje">
          <img src={tusas1} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("tusas")}
            <br />
            <span>{t("project-sector")}</span>: {t("aerospace")}
            <br />
            <span>{t("project-subject")}</span>: {t("logonun-yorumu")}
            <br />
            <span>{t("project-artwork")}</span>: {t("allah-cc-tekrari")}
          </div>
          <img src={tusas2} alt="" className="proje-icGorsel" />
        </div>
        <div className="seperator"></div>
        <div className="ic-proje">
          <img src={tusas3} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("tusas")}
            <br />
            <span>{t("project-sector")}</span>: {t("aerospace")}
            <br />
            <span>{t("project-subject")}</span>: {t("helikopterin-yorumu")}
            <br />
            <span>{t("project-artwork")}</span>: {t("allahuekber")}
          </div>
          <img src={tusas4} alt="" className="proje-icGorsel" />
        </div>
        <div className="seperator"></div>
        <div className="ic-proje">
          <img src={tusas5} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("tusas")}
            <br />
            <span>{t("project-sector")}</span>: {t("aerospace")}
            <br />
            <span>{t("project-subject")}</span>: {t("vip-takvim")}
            <br />
            <span>{t("project-artwork")}</span>: {t("onuc-eser")}
          </div>
          <img src={tusas6} alt="" className="proje-icGorsel" />
        </div>
        <div className="seperator"></div>
        <div className="ic-proje">
          <img src={tusas7} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("tusas")}
            <br />
            <span>{t("project-sector")}</span>: {t("aerospace")}
            <br />
            <span>{t("project-subject")}</span>: {t("sanat-galerisi")}
            <br />
            <span>{t("project-artwork")}</span>: {t("onalti-sanat-eseri")}
          </div>
          <img src={tusas8} alt="" className="proje-icGorsel" />
        </div>
      </div>
    </Layout>
  )
}
